
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import CellWithSubtitle from '@/components/tableWithActions/CellWithSubtitle.vue';
import {
  CutCurrentWorkloadDataMessages,
  CutCurrentWorkloadDataPlanInfo,
  MessageLevel,
} from '@/models/Charts/chartsData';
import { isEmpty } from '@/utils/misc';
import { formatDuration } from '@/utils/dates';
import { formatDateWithTime } from '@/utils/table';
import { Customer } from '@/models/tenant';
import i18n from '@/i18n';
import TableActionButton2 from '../tableWithActions/TableActionButton2.vue';
import { BubbleType } from '../ActionBubble.vue';
import CutCellControlActiveMessages from './CutCellControlActiveMessages.vue';
import {
  GroupedCutCurrentWorkloadData,
} from '@/models/Charts/slotted/cutCurrentWorkloadTableDataRetriever';
import { modalService } from '@/library-services/modal.service';
import TableActionsList from '../tableWithActions/TableActionsList.vue';
import TableColumnActionsWrapper from '../tableWithActions/TableColumnActionsWrapper.vue';
import GenericKeyCopyModal from '../GenericKeyCopyModal.vue';
import { lengthUnit } from '@/utils/measurement';
import { customersService } from '@/services/tenants.service';

@Component({
  components: {
    CellWithSubtitle,
    TableActionButton2,
    TableWithActions2,
    TableActionsList,
    TableColumnActionsWrapper,
  },
  methods: {
    formatDuration,
    formatDateWithTime,
    isEmpty,
  },
})
export default class CutCurrentWorkloadTable extends Vue {
  @Prop({ required: true })
  private data!: GroupedCutCurrentWorkloadData[] | null;

  @Prop({ required: true })
  private loading!: boolean;

  private get coalescedData(): GroupedCutCurrentWorkloadData[] {
    return this.data ?? [];
  }

  private get customer(): Customer {
    return customersService.store.current()!;
  }

  private get measurementUnit(): string {
    return lengthUnit();
  }

  private formatDimensions(width: number, height: number): string {
    return `${this.truncate(width)} x ${this.truncate(height)}`;
  }

  private truncate(n: number): number {
    return Math.round(n);
  }

  private openMessageModal(row: GroupedCutCurrentWorkloadData) {
    modalService.open({
      parent: this,
      component: CutCellControlActiveMessages,
      width: 900,
      props: {
        messages: row.currentMessages,
      },
    });
  }

  private openNcPathModal(detail: CutCurrentWorkloadDataPlanInfo) {
    modalService.open({
      parent: this,
      component: GenericKeyCopyModal,
      canCancel: ['outside'],
      props: {
        value: detail.ncFilePath,
        title: i18n.t('cut_current_workload_table.actions.nc_path_title'),
      },
    });
  }

  private getBubbleContent(messages: CutCurrentWorkloadDataMessages[]): string | undefined {
    if (isEmpty(messages)) {
      return undefined;
    }
    return messages.length.toString();
  }

  private getBubbleType(messages: CutCurrentWorkloadDataMessages[]): BubbleType | undefined {
    if (isEmpty(messages)) {
      return undefined;
    }
    if (messages.some((x) => x.messageLevel === MessageLevel.Error)) {
      return 'is-danger';
    }
    if (messages.some((x) => x.messageLevel === MessageLevel.Warning)) {
      return 'is-warning';
    }
    return 'is-info';
  }
}
